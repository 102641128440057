smallsmallsmallsmallsmall
<template>
  <b-overlay :show="show" rounded="sm" no-fade>
    <b-card-code title="Masukkan ID SIP">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="ID SIP"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ID SIP"
                  rules="required"
                >
                  <b-form-input
                    v-model="id_sip"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukkan ID SIP"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "ID SIP Wajib Diisi" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6" align="end">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="submitDuplicate()"
              >
                Kirim
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-modal
        id="modal-detail-payment-upload"
        ref="ref-download-sip"
        title="Download SIP"
        :hide-footer="true"
        no-close-on-backdrop
      >
        <b-form>
          <p>Silahkan download data SIP untuk melakukan duplikat SIP</p>
        </b-form>

        <b-row class="mt-3">
          <b-col align="end">
            <b-button variant="secondary" class="mr-2" @click="kembaliModal()">
              Kembali
            </b-button>
            <b-button variant="success" @click="downloadSIP()">
              Download SIP
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-card-code>
  </b-overlay>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import axios from "@axios";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormCheckbox,
    BOverlay,
    flatPickr,
  },
  data() {
    return {
      required,
      show: false,

      id_sip: "",
      uuid: "",
      is_download: false,
    };
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    submitDuplicate() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          return new Promise(() => {
            axios
              .get("pendaftar/get_data_sip_by_id?id_sip=" + this.id_sip)
              .then((res) => {
                if (res.data.status_perizinan === 1) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   position: "top-right",
                  //   props: {
                  //     title: `SIP sudah di verifikasi`,
                  //     icon: "CheckCircleIcon",
                  //     variant: "success",
                  //     text: `Silahkan download SIP untuk melakukan duplikat SIP`,
                  //   },
                  // });

                  if (res.data.uuid === null) {
                    this.uuid = "";
                  } else {
                    this.uuid = res.data.uuid;
                  }

                  this.$refs["ref-download-sip"].show();
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:
                        "Tidak dapat duplikat SIP jika SIP belum di verifikasi",
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });
                }
              })
              .catch((error) => {
                if (error.response.status == 401) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });
                  this.$router.push("/login");
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });
                }
              });
          });
        } else {
          return;
        }
      });
    },

    downloadSIP() {
      this.$refs["ref-download-sip"].hide();
      window.open(
        "https://sipolnakes.salatiga.go.id/api/v1/pendaftar/download_sip?id_sip=" +
          this.uuid
      );
    },

    kembaliModal() {
      this.$refs["ref-download-sip"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
